import { Button, Label, ScrollView, Text, View, XStack, YStack } from "tamagui";
import { Provider, useAtom } from "jotai";
import { Ride } from "./types";
import { rideAtom } from "./atoms";
import { Keyboard, KeyboardAvoidingView } from "react-native";
import { Input, TextArea } from "../design-system";
import { ArrowRightIcon } from "../design-system/icons/ArrowRightIcon";
import {
  PillSelect,
  PillSelectOption,
} from "../design-system/PillSelect/PillSelect";
import DateTimePicker from "@react-native-community/datetimepicker";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { LocationModal } from "./components/LocationModal";
import { RouteModal } from "./components/RouteModal";
import { VisibilityModal } from "./components/VisibilityModal";
import { InvitesModal } from "../InvitesModal/InvitesModal";
import { brandShadow, brandShadowSm } from "../../../tamagui.config";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { de } from "date-fns/locale";

const terrainOptions: PillSelectOption[] = [
  { label: "Road", value: "Road" },
  { label: "Gravel", value: "Gravel" },
  { label: "Mixed", value: "Mixed" },
  { label: "MTB", value: "MTB" },
];
const paceOptions: PillSelectOption[] = [
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "C",
    value: "C",
  },
  {
    label: "D",
    value: "D",
  },
  {
    label: "Party",
    value: "Party",
  },
];

const getRideVisibility = (visibility: string | null) => {
  switch (visibility) {
    case "Public":
      return "Public: Anyone in Breakfast Club";
    case "FriendsOnly":
      return "Friends: Only people I am Friends With";
    case "Private":
      return "Only People I Invite (Via the link)";
    default:
      return "Public";
  }
};

type RideFormProps = {
  handleSubmit: (ride: Ride) => void;
  currentUserId: string;
  defaultValues?: Ride;
  isEditing?: boolean;
};

export const RideForm = (props: RideFormProps) => {
  return (
    <Provider>
      <RideFormInner {...props} />
    </Provider>
  );
};

export const RideFormInner = ({
  defaultValues,
  handleSubmit,
  currentUserId,
  isEditing,
}: RideFormProps) => {
  const [ride, setRide] = useAtom(rideAtom);

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [isVisibiltyModalOpen, setIsVisibiltyModalOpen] = useState(false);
  const [isInvitesModalOpen, setIsInvitesModalOpen] = useState(false);

  useEffect(() => {
    if (defaultValues) setRide(defaultValues);
  }, [defaultValues]);

  const { name, description, terrain, groups } = ride ?? {};
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    if (isEditing) {
      if (defaultValues?.date && defaultValues?.time) {
        const date = parse(defaultValues?.date ?? "", "yyyy-MM-dd", new Date());
        const time = parse(defaultValues?.time ?? "", "HH:mm:ss", new Date());
        setDate(date);
        setTime(time);
      }
    }
  }, [isEditing, defaultValues]);

  const canSubmit =
    !!name &&
    !!ride?.location &&
    !!terrain &&
    !!ride.groups?.length &&
    !!ride.route?.id;

  const onSubmit = () => {
    const rideInput = {
      ...ride,
      time: ride?.time ?? format(new Date(), "HH:mm:ss"),
      date: ride?.date ?? format(new Date(), "yyyy-MM-dd"),
    };
    if (rideInput) handleSubmit(rideInput);
  };

  const { bottom } = useSafeAreaInsets();

  return (
    <>
      <ScrollView
        flex={1}
        keyboardShouldPersistTaps="handled"
        bg="$brandParchment"
        contentContainerStyle={{
          paddingBottom: bottom,
        }}
      >
        <KeyboardAvoidingView>
          <YStack gap="$1" flex={1} w="100%" px="$4">
            <View>
              <Label onPress={() => Keyboard.dismiss()}>Name</Label>
              <Input
                placeholder="Name"
                value={name ?? ""}
                onChangeText={(text) => {
                  setRide({ ...ride, name: text });
                }}
              />
            </View>
            <View>
              <Label onPress={() => Keyboard.dismiss()}>Description</Label>
              <TextArea
                numberOfLines={4}
                placeholder="Tell us about the ride! (Stops, etc)"
                value={description ?? ""}
                style={{
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: "black",

                  paddingVertical: 16,
                  paddingHorizontal: 12,
                  fontSize: 18,
                }}
                onChangeText={(text) => {
                  setRide({ ...ride, description: text });
                }}
              />
            </View>
            <View>
              <Label>Where are you starting?</Label>
              <Button
                bg="white"
                width="100%"
                justifyContent="space-between"
                onPress={() => setIsLocationModalOpen(true)}
                pressStyle={{
                  backgroundColor: "$brandParchment",
                }}
                py="$4"
                borderWidth={1}
                borderColor="black"
                {...brandShadowSm}
                h={60}
              >
                <Text
                  fontSize={16}
                  color={ride?.location ? "black" : "rgba(0,0,0,0.70)"}
                  flexShrink={1}
                >
                  {ride?.location ?? "Choose a location"}
                </Text>
                <ArrowRightIcon width={20} height={20} />
              </Button>
            </View>
            <View>
              <Label>Where are you going?</Label>
              <Button
                bg="white"
                width="100%"
                justifyContent="space-between"
                onPress={() => setIsRouteModalOpen(true)}
                pressStyle={{
                  backgroundColor: "$brandParchment",
                }}
                py="$4"
                borderWidth={1}
                borderColor="black"
                {...brandShadowSm}
                h={60}
              >
                <Text color={ride?.route?.id ? "black" : "rgba(0,0,0,0.70)"}>
                  {ride?.route?.name ?? "Choose a route"}
                </Text>
                <ArrowRightIcon width={20} height={20} />
              </Button>
            </View>

            <View>
              <Label>Visiblity Mode</Label>
              <Button
                bg="white"
                width="100%"
                justifyContent="space-between"
                onPress={() => setIsVisibiltyModalOpen(true)}
                pressStyle={{
                  backgroundColor: "$brandParchment",
                }}
                borderWidth={1}
                borderColor="black"
                {...brandShadowSm}
                py="$4"
                h={60}
              >
                <Text color={ride?.visibility ? "black" : "rgba(0,0,0,0.70)"}>
                  {getRideVisibility(ride?.visibility ?? "")}
                </Text>
                <ArrowRightIcon width={20} height={20} />
              </Button>
            </View>

            {ride?.visibility === "Private" && !isEditing ? (
              <View>
                <Label>Who can see it?</Label>
                <Button
                  bg="white"
                  width="100%"
                  justifyContent="space-between"
                  onPress={() => setIsInvitesModalOpen(true)}
                  pressStyle={{
                    backgroundColor: "$brandParchment",
                  }}
                  py="$4"
                  borderWidth={1}
                  borderColor="black"
                  {...brandShadowSm}
                  h={60}
                >
                  <Text color={ride?.visibility ? "black" : "rgba(0,0,0,0.70)"}>
                    {!ride.invited_users?.length ||
                    ride.invited_users?.length === 0
                      ? "Invite some people!"
                      : `${ride.invited_users?.length} ${
                          ride.invited_users?.length &&
                          ride.invited_users?.length > 1
                            ? "people"
                            : "person"
                        } invited`}
                  </Text>
                  <ArrowRightIcon width={20} height={20} />
                </Button>
              </View>
            ) : null}
            <XStack justifyContent="space-between" mt="$4">
              <Label onPress={() => Keyboard.dismiss()}>Date</Label>

              <DateTimePicker
                testID="dateTimePicker"
                value={date}
                mode={"date"}
                is24Hour={false}
                onChange={(e, d) => {
                  if (d) {
                    setDate(d);
                    const formattedDate = format(d, "yyyy-MM-dd");
                    setRide({ ...ride, date: formattedDate });
                  }
                }}
              />
            </XStack>
            <XStack justifyContent="space-between">
              <Label onPress={() => Keyboard.dismiss()}>Time</Label>
              <DateTimePicker
                testID="dateTimePicker"
                value={time}
                mode={"time"}
                is24Hour={false}
                onChange={(e, t) => {
                  if (t) {
                    setTime(t);
                    const formattedTime = format(t, "HH:mm:ss");
                    setRide({ ...ride, time: formattedTime });
                  }
                }}
              />
            </XStack>

            <View>
              <PillSelect
                label="Terrain"
                value={terrain ?? ""}
                setValue={(string) =>
                  setRide({ ...ride, terrain: string as any })
                }
                options={terrainOptions}
              />
            </View>
            <View>
              <PillSelect
                label="Pace"
                value={groups?.[0] ?? ""}
                setValue={(string) =>
                  setRide({ ...ride, groups: [string as any] })
                }
                options={paceOptions}
              />
            </View>
            <Button
              onPress={onSubmit}
              mt="$4"
              opacity={canSubmit ? 1 : 0.5}
              disabled={!canSubmit}
              bg="$brandYellow"
              pressStyle={{
                bg: "$brandYellow",
                shadowColor: "$colorTransparent",
                shadowOffset: { width: 0, height: 0 },
                borderWidth: 1,
                borderColor: "black",
                scale: 0.98,
              }}
              borderWidth={1}
              fontSize={16}
              fontFamily="$heading"
              borderColor={"black"}
              {...brandShadow}
            >
              SUBMIT
            </Button>
          </YStack>
        </KeyboardAvoidingView>
      </ScrollView>

      <LocationModal
        isOpen={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
      />

      <RouteModal
        isOpen={isRouteModalOpen}
        onClose={() => setIsRouteModalOpen(false)}
        userId={currentUserId}
      />

      <VisibilityModal
        isOpen={isVisibiltyModalOpen}
        onClose={() => setIsVisibiltyModalOpen(false)}
      />

      <InvitesModal
        isOpen={isInvitesModalOpen}
        onClose={() => setIsInvitesModalOpen(false)}
        userId={currentUserId}
        onContinue={(invitedUsers) => {
          setRide({ ...ride, invited_users: invitedUsers });
          setIsInvitesModalOpen(false);
        }}
        defaultInvitedUsers={ride?.invited_users ?? []}
      />
    </>
  );
};
