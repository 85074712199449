import { SafeAreaView } from "react-native-safe-area-context";
import { Text, View, XStack } from "tamagui";
import { customColorTokens } from "../../../../tamagui.config";
import React from "react";

type HeaderProps = {
  title: string | React.ReactNode;
  headerCenter?: React.ReactNode;
  headerRight?: React.ReactNode;
  hideBorder?: boolean;
};

export const Header = ({
  title,
  headerCenter,
  headerRight,
  hideBorder = false,
}: HeaderProps) => {
  return (
    <SafeAreaView
      edges={["top"]}
      style={{
        backgroundColor: customColorTokens.brandParchment,
        zIndex: 100,
      }}
    >
      <XStack
        justifyContent="space-between"
        alignItems="center"
        px="$4"
        pb={18}
        bg={customColorTokens.brandParchment}
        borderBottomWidth={hideBorder ? 0 : 1}
        $platform-web={{ paddingTop: "$3" }}
        pos="relative"
        h={40}
      >
        <View justifyContent="center" flexGrow={1} alignItems="flex-start">
          {typeof title === "string" ? (
            <Text
              $platform-web={{ fontSize: 20 }}
              $platform-native={{ fontSize: "$2" }}
              textTransform="uppercase"
              fontWeight={900}
              fontFamily="$heading"
              adjustsFontSizeToFit
            >
              {title}
            </Text>
          ) : title ? (
            title
          ) : null}
        </View>
        <View flexGrow={1}>{headerCenter && headerCenter}</View>
        {headerRight ? headerRight : <View h="4" w="4" bg="black" />}
      </XStack>
    </SafeAreaView>
  );
};
