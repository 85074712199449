import {
  Link,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { ScrollView, Text, View, XStack, Button, Heading } from "tamagui";
import { RidesNavigatorParamList } from "../../../navigators/CreateARideNavigator";
import { CloseIcon, LinkIcon } from "../../../components/design-system/icons";
import ViewShot from "react-native-view-shot";

import { useRef } from "react";
import { Linking, Platform, Pressable } from "react-native";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import * as Clipboard from "expo-clipboard";
import * as Burnt from "burnt";
import { EventCard } from "../../../components/design-system/EventCard";
import { useGetEvent } from "../../../hooks/useGetEvent";
import { Pill } from "../../../components/design-system/Pill";

export const ShareScreen = () => {
  const route = useRoute<RouteProp<RidesNavigatorParamList, "Share">>();
  const navigation = useNavigation();

  const eventId = route.params.eventId;

  const { event } = useGetEvent(eventId);

  console.log("eventId", event);

  const eventUrl = `https://rides.breakfastclubatx.com/events/${eventId}}`;

  const imageRef = useRef<ViewShot>(null);

  const { bottom } = useSafeAreaInsets();

  const copyUrl = async () => {
    if (Platform.OS === "web") {
      navigator.clipboard.writeText(eventUrl);
    } else {
      await Clipboard.setUrlAsync(eventUrl);
    }
    Burnt.toast({
      title: "Link copied to clipboard",
      haptic: "success",
    });
  };

  return (
    <View flex={1} backgroundColor="$brandParchment">
      <View
        px="$4"
        pb="$4"
        justifyContent="flex-start"
        alignItems="flex-start"
        top={16}
        left={0}
        right={0}
      >
        <XStack justifyContent="space-between" w="100%" alignItems="center">
          <View>
            <HeaderButton
              onPress={() => {
                const p = navigation.getParent();
                if (p) {
                  p.goBack();
                } else {
                  navigation.goBack();
                }
              }}
            >
              <CloseIcon color="black" width={16} height={16} />
            </HeaderButton>
          </View>
          <View justifyContent="flex-end" alignItems="flex-end">
            <Text fontSize={10}>Rides by Breakfast Club</Text>
            <Text fontFamily="$italicHeadingFont" fontSize={16}>
              Share Ride
            </Text>
          </View>
        </XStack>
      </View>
      <View flex={1} mt="$10">
        <ViewShot
          ref={imageRef}
          options={{ fileName: "member-card", format: "png", quality: 1 }}
          style={{
            width: "100%",
            position: "relative",
            transform: [{ scale: 0.9 }],
          }}
        >
          {event ? <EventCard event={event} /> : null}
        </ViewShot>
        <View w="100%" px="$4" mt="$4">
          <Heading fontSize={16} textTransform="uppercase" mb="$2">
            Share To
          </Heading>
          <XStack gap="$2">
            <Pressable onPress={copyUrl}>
              <Pill
                icon={<LinkIcon width={20} height={20} />}
                width={40}
                height={40}
                bg="white"
                borderColor="black"
              />
            </Pressable>
          </XStack>
        </View>
      </View>
    </View>
  );
};
