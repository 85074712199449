import { Modal } from "react-native";
import { ScrollView, Text, View } from "tamagui";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { HeaderButton } from "../../../../components/design-system/Button/HeaderButton";
import { customColorTokens } from "../../../../../tamagui.config";
import { CloseIcon } from "../../../../components/design-system/icons";
import { MemberListItem } from "../../../../components/MemberListItem";
import { Event } from "../../../../hooks/useGetEvent";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { BaseNavigatorParamList } from "../../../../navigators/BaseNavigator";

type EventDetailAttendeesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  event: Event;
};
export const EventDetailAttendeesModal = ({
  isOpen,
  onClose,
  event,
}: EventDetailAttendeesModalProps) => {
  const { top } = useSafeAreaInsets();

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => {
        onClose();
      }}
      style={{ flex: 1, backgroundColor: customColorTokens.brandParchment }}
      animationType="fade"
    >
      <View flex={1} pos="relative" backgroundColor="$brandParchment">
        <View top={top} pos="absolute" left="$4" zIndex={1000}>
          <HeaderButton onPress={() => onClose()}>
            <CloseIcon width={16} height={16} />
          </HeaderButton>
        </View>
        <View paddingTop={top + 72} px="$4" flex={1}>
          <Text fontSize={20} fontFamily="$heading">
            ATTENDEES
          </Text>
          <ScrollView showsVerticalScrollIndicator={false}>
            {event.attendees?.map((e, i) => (
              <MemberListItem
                key={e.user_id}
                id={e.user_id}
                name={e.name ?? ""}
                role={e.role ?? "Member"}
                avatarUrl={e.avatar_url ?? null}
                hideArrow
                onPress={(id) => {}}
              />
            ))}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};
