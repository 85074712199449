import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { View, ScrollView, Text, Heading, XStack, YStack } from "tamagui";
import { currentUserRoleAtom } from "../../../../atoms/global";

import { userProfileStateAtom } from "../../../features/Profile/atoms";
import { useNavigation, NavigationProp } from "@react-navigation/native";
import { customColorTokens } from "../../../../tamagui.config";
import { Header } from "../../../components/design-system/Header";
import { ClubNavigatorParamList } from "../../../navigators/ClubNavigator";
import { iProfile } from "../../../features/Profile";
import { Button } from "../../../components/design-system";

import { CardFlip } from "../../../components/CardFlip";
import { Image, ImageBackground, Pressable } from "react-native";
import { ProfileCard } from "../../../components/ProfileCard";

import * as Linking from "expo-linking";
import { graphql } from "relay-hooks";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { ProfileQuery } from "./__generated__/ProfileQuery.graphql";
import { PartnersList } from "./components/PartnersList";
import { Settings } from "@tamagui/lucide-icons";
import { ArrowRightIcon } from "../../../components/design-system/icons/ArrowRightIcon";

type ProfileProps = {
  queryRef: PreloadedQuery<ProfileQuery>;
};

export const Profile = ({ queryRef }: ProfileProps) => {
  const data = usePreloadedQuery<ProfileQuery>(ProfileQueryString, queryRef);
  const profile = data.profilesCollection?.edges?.[0]?.node;
  const setProfileState = useSetAtom(userProfileStateAtom);

  useEffect(() => {
    setProfileState({ ...profile } as iProfile);
  }, [profile, setProfileState]);

  const navigation = useNavigation<NavigationProp<ClubNavigatorParamList>>();

  const userRole = useAtomValue(currentUserRoleAtom);

  return (
    <View
      flex={1}
      style={{ flex: 1, backgroundColor: customColorTokens.brandYellow }}
    >
      <Header
        title="Club"
        headerRight={
          <XStack gap="$2">
            <Pressable onPress={() => navigation.navigate("EditProfile")}>
              <Settings width={24} height={24} color="black" />
            </Pressable>
          </XStack>
        }
      />

      <ScrollView
        flex={1}
        bg={customColorTokens.brandYellow}
        contentContainerStyle={{
          backgroundColor: customColorTokens.brandParchment,
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
      >
        <View flex={1}>
          <ScrollView pt="$4">
            <YStack
              flex={1}
              $gtMd={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardFlip
                front={
                  <View flex={1} w="100%" bg="$brandParchment">
                    <ImageBackground
                      style={{
                        flex: 1,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        overflow: "hidden",
                        borderRadius: 20,
                      }}
                      source={require("../../../../assets/pattern.png")}
                    >
                      <XStack
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        w="100%"
                      >
                        <Image
                          style={{
                            objectFit: "contain",
                            height: "90%",
                            width: "75%",
                          }}
                          resizeMode="contain"
                          source={require("../../../../assets/card-back.png")}
                        />
                      </XStack>
                    </ImageBackground>
                  </View>
                }
                back={
                  <View
                    flex={1}
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ProfileCard
                      fragmentRef={
                        data.profilesCollection?.edges[0].node ?? null
                      }
                    />
                  </View>
                }
                isProfileLoaded={!!data.profilesCollection?.edges[0].node}
              />

              <YStack gap="$4" mt="$4" py="$2" position="relative" flex={1}>
                <YStack gap="$2" px="$4">
                  <Button
                    bg="white"
                    margin={0}
                    p={0}
                    mb="$2"
                    pressStyle={{
                      scaleX: 0.99,
                      scaleY: 0.99,
                      bg: "white",
                      shadowOffset: { height: 1, width: 1 }, // Reduced shadow offset for pressed effect
                      shadowOpacity: 0.7, // Lower opacity for a softer shadow when pressed
                      // Keep the shadow radius unchanged or adjust slightly if needed
                    }}
                    {...{
                      shadowColor: "black",
                      shadowOffset: { width: 2, height: 2 },
                      shadowOpacity: 0.9,
                      shadowRadius: 0,
                    }}
                    onPress={() => navigation.navigate("FriendsAndRequests")}
                  >
                    <XStack
                      w="100%"
                      p="$4"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text fontFamily="$heading" fontSize={16}>
                        Friends & Community
                      </Text>
                      <ArrowRightIcon width={20} height={20} />
                    </XStack>
                  </Button>
                </YStack>

                <YStack gap="$2" px="$4">
                  <Text
                    fontSize={16}
                    textTransform="uppercase"
                    fontFamily="$heading"
                  >
                    Partners & Perks
                  </Text>
                  {userRole == "Member" ? (
                    <YStack
                      gap="$4"
                      mt="$10"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <YStack
                        gap="$1"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text
                          fontFamily="$heading"
                          fontSize={20}
                          textAlign="center"
                        >
                          Want more Breakfast?
                        </Text>
                        <Text textAlign="center">Join CLUB.</Text>
                      </YStack>

                      <Button
                        variant="outlined"
                        bg="white"
                        fontSize={16}
                        hoverStyle={{
                          backgroundColor: "white",
                          borderColor: "black",
                        }}
                        onPress={() =>
                          Linking.openURL("https://breakfastclubatx.com/club")
                        }
                      >
                        Learn More
                      </Button>
                    </YStack>
                  ) : (
                    <PartnersList fragmentRef={data} />
                  )}
                </YStack>
              </YStack>
            </YStack>
          </ScrollView>
        </View>
      </ScrollView>
    </View>
  );
};

export const ProfileQueryString = graphql`
  query ProfileQuery($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }, first: 1) {
      edges {
        node {
          ...ProfileCard_fragment
        }
      }
    }
    ...PartnersList_fragment
  }
`;
