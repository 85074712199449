import {
  Heading,
  Image,
  ScrollView,
  Text,
  View,
  XStack,
  YStack,
} from "tamagui";

import { graphql } from "relay-hooks";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { MemberQuery } from "./__generated__/MemberQuery.graphql";
import Avatar from "../../components/Avatar";
import { ArrowLeftIcon, StarIcon } from "../../components/design-system/icons";
import { HeaderButton } from "../../components/design-system/Button/HeaderButton";
import { useNavigation } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { format } from "date-fns";
import { brandShadowSm } from "../../../tamagui.config";
import { Linking } from "react-native";

import { FriendshipButton } from "../../components/FriendShipButton";

type MemberProps = {
  queryRef: PreloadedQuery<MemberQuery>;
};

export const Member = ({ queryRef }: MemberProps) => {
  const data = usePreloadedQuery<MemberQuery>(MemberQueryString, queryRef);
  const member = data.profilesCollection?.edges?.[0]?.node;
  const role = member?.user_rolesCollection?.edges?.[0]?.node?.role ?? "Member";

  const navigation = useNavigation();

  const { top } = useSafeAreaInsets();

  const date = new Date(member?.created_at);

  const formattedDate = format(date, "MM/yy");

  return (
    <ScrollView
      w="100%"
      flex={1}
      contentContainerStyle={{
        backgroundColor: "$brandParchment",
      }}
    >
      <Image
        src={require("../../../assets/pattern.png")}
        resizeMode="repeat"
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        opacity={0.2}
      />
      <View pt={top}>
        <View
          px="$4"
          pb="$2"
          justifyContent="space-between"
          alignItems="flex-start"
          flexDirection="row"
        >
          <HeaderButton onPress={() => navigation.goBack()}>
            <ArrowLeftIcon color="black" width={16} height={16} />
          </HeaderButton>
          <FriendshipButton userId={member?.id} />
        </View>
        <View
          pb="$4"
          px="$4"
          w="100%"
          borderBottomWidth={1}
          justifyContent="center"
          alignItems="center"
        >
          <View pos="relative">
            <Avatar
              size={200}
              imageStyle={{
                borderRadius: 999,
                borderColor: "black",
                borderWidth: 1,
              }}
              url={member?.avatar_url ?? null}
            />
            {role !== "Member" ? (
              <View
                bottom={-4}
                right={-4}
                justifyContent="space-between"
                alignItems="center"
                pos="absolute"
                p="$2"
              >
                <StarIcon width={50} height={50} />
              </View>
            ) : null}
          </View>
        </View>
      </View>

      <View flex={1} pos="relative">
        <View
          justifyContent="flex-start"
          pos="relative"
          px="$4"
          pt="$4"
          gap="$2"
        >
          <Heading
            size="$4"
            textTransform="uppercase"
            fontFamily="$heading"
            flexShrink={0}
          >
            {member?.name}
          </Heading>
        </View>

        <View p="$4" gap="$4">
          <Text>About</Text>

          <YStack
            gap="$1"
            bg="$brandYellow"
            borderRadius={20}
            p="$2"
            justifyContent="center"
            alignItems="center"
            borderWidth={1}
            minHeight={80}
            {...brandShadowSm}
          >
            <Text fontSize={20}>{member?.breakfast_food}</Text>

            <Text fontFamily="$heading" fontSize={10} textTransform="uppercase">
              Favorite Breakfast Food
            </Text>
          </YStack>
          <XStack gap="$4" justifyContent="space-between">
            <View
              bg="$brandYellow"
              borderRadius={20}
              borderWidth={1}
              height={80}
              flexGrow={1}
              justifyContent="center"
              alignItems="center"
              {...brandShadowSm}
            >
              <Text fontSize={20} zIndex={10}>
                {formattedDate}
              </Text>
              <Text
                fontFamily="$heading"
                textTransform="uppercase"
                fontSize={10}
                zIndex={10}
              >
                Member Since
              </Text>
            </View>
          </XStack>

          {/* <YStack
            gap="$1"
            bg="$brandYellow"
            borderRadius={20}
            p="$2"
            justifyContent="center"
            alignItems="center"
            borderWidth={1}
            minHeight={80}
            {...brandShadowSm}
          >
            <XStack gap="$2">
              {groupIcons ? (
                groupIcons
              ) : (
                <Text>{`This user doesn't have any favorite groups :(`}</Text>
              )}
            </XStack>

            <Text fontFamily="$heading" fontSize={10} textTransform="uppercase">
              Favorite Groups
            </Text>
          </YStack> */}

          {member?.emergency_contact_name && member.emergency_contact_number ? (
            <>
              <Text>Safety</Text>
              <YStack
                gap="$1"
                bg="$red10Dark"
                borderRadius={20}
                p="$2"
                justifyContent="center"
                alignItems="center"
                borderWidth={1}
                minHeight={80}
                onPress={() =>
                  Linking.openURL(`tel:${member?.emergency_contact_number}`)
                }
                {...brandShadowSm}
              >
                <Text fontSize={20} zIndex={10} color="white">
                  {member?.emergency_contact_name ?? "Unknown"}
                </Text>
                <Text
                  fontFamily="$heading"
                  fontSize={10}
                  textTransform="uppercase"
                  color="white"
                >
                  Emergency Contact (Tap to call)
                </Text>
              </YStack>
            </>
          ) : null}
        </View>
      </View>
    </ScrollView>
  );
};

export const MemberQueryString = graphql`
  query MemberQuery($id: UUID!) {
    profilesCollection(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          ...ProfileCard_fragment
          id
          name
          avatar_url
          bio
          created_at
          strava_code
          breakfast_food
          instagram
          member_number
          emergency_contact_name
          emergency_contact_number

          favorite_groups
          user_rolesCollection {
            edges {
              node {
                role
              }
            }
          }
        }
      }
    }
  }
`;
