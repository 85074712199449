import { Button, Heading, ScrollView, Text, View, XStack } from "tamagui";
import { EventCard } from "../../../../components/design-system/EventCard";
import { brandShadow } from "../../../../../tamagui.config";
import {
  NavigationProp,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { useEffect, useState } from "react";
import { supabase } from "../../../../../lib/supabase";
import { currentUserAtom } from "../../../../../atoms/global";
import { useAtomValue } from "jotai";
import { iProfile } from "../../../../features/Profile";
import * as Burnt from "burnt";
import { BaseNavigatorParamList } from "../../../../navigators/BaseNavigator";
import { Event } from "../../../../hooks/useGetEvent";

type Invite = {
  id: string;
  event: Event;
  status: string;
  user: iProfile;
};

export const MyInvites = () => {
  const [invites, setInvites] = useState<Invite[] | null>(null);

  const isFocused = useIsFocused();

  const user = useAtomValue(currentUserAtom);

  const navigation = useNavigation<NavigationProp<BaseNavigatorParamList>>();

  useEffect(() => {
    if (isFocused) {
      supabase
        .from("invites")
        .select(
          "*, event:events(*, route:routes(*), host:profiles(*)), user:profiles(*)"
        )
        .eq("recipient_id", user?.id)
        .eq("status", "pending")
        .order("created_at", { ascending: false })
        .then(({ data }) => {
          const invitesMap = data?.map((i) => ({
            id: i.id,
            user: i.user,
            event: i.event,
            status: "pending",
          }));

          setInvites(invitesMap ?? []);
        });
    }
  }, [isFocused, setInvites]);

  const acceptInvite = async (invite: Invite) => {
    const { error } = await supabase
      .from("invites")
      .update({ status: "accepted" })
      .eq("id", invite.id);

    if (error) {
      console.error(error);
    } else {
      Burnt.toast({
        title: "Invite Accepted",
        haptic: "success",
      });
      setInvites(invites?.filter((i) => i.id !== invite.id) ?? []);

      navigation.navigate("EventDetail", { eventId: invite.event.id });
    }
  };

  const declineInvite = async (invite: Invite) => {
    const { error } = await supabase
      .from("invites")
      .update({ status: "declined" })
      .eq("id", invite.id);

    if (error) {
      console.error(error);
    } else {
      Burnt.toast({
        title: "Invite Declined",
        haptic: "error",
      });
      setInvites(invites?.filter((i) => i.id !== invite.id) ?? []);
    }
  };

  return (
    <ScrollView px="$4">
      <Heading
        fontSize="$4"
        textTransform="uppercase"
        py="$4"
        pb={invites?.length === 0 ? "$2" : "$4"}
      >
        Your Invites
      </Heading>
      {invites?.length === 0 ? (
        <View px="$4" justifyContent="center" alignItems="center" h={100}>
          <Text>{`You don't have any invites`}</Text>
        </View>
      ) : null}
      {invites?.map((i) => (
        <View mb="$4" key={i.id}>
          <EventCard event={i.event} />
          <XStack gap="$2" mt="$2">
            <Button
              size="$3"
              fontSize={12}
              bg="#E15425"
              color="white"
              fontFamily={"$heading"}
              flex={1}
              pressStyle={{
                bg: "#E15425",
                shadowColor: "$colorTransparent",
                shadowOffset: { width: 0, height: 0 },
                borderWidth: 1,
                borderColor: "black",
                scale: 0.98,
              }}
              borderWidth={1}
              borderColor={"black"}
              onPress={() => declineInvite(i)}
              {...brandShadow}
            >
              DECLINE
            </Button>

            <Button
              size="$3"
              fontSize={12}
              bg="$green10Light"
              borderWidth={1}
              borderColor="black"
              color="white"
              flex={1}
              fontFamily={"$heading"}
              onPress={() => acceptInvite(i)}
              pressStyle={{
                bg: "$brandYellow",
                shadowColor: "$colorTransparent",
                shadowOffset: { width: 0, height: 0 },
                borderWidth: 1,
                borderColor: "black",
                scale: 0.98,
              }}
              {...brandShadow}
            >
              ACCEPT
            </Button>
          </XStack>
          <View bg="black" h={1} w="100%" mt="$4" />
        </View>
      ))}
    </ScrollView>
  );
};
