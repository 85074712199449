import { useCallback, useEffect, useState } from "react";
import { supabase } from "../../lib/supabase";

import { useIsFocused } from "@react-navigation/native";
import { iProfile } from "../features/Profile";

export const useSearchMembers = (searchTerm?: string) => {
  const isFocused = useIsFocused();
  const [members, setMembers] = useState<iProfile[]>([]);

  const fetchMembers = useCallback(async () => {
    if (!searchTerm) {
      return;
    }
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .ilike("name", `%${searchTerm}%`);

    if (error) {
      console.error("Error fetching friends:", error);
      return;
    }

    setMembers(data ?? []);
  }, [searchTerm]);

  useEffect(() => {
    if (isFocused) {
      fetchMembers();
    }
  }, [isFocused, fetchMembers]);

  return { members } as const;
};
