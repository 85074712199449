import { OpaqueColorValue } from "react-native";
import {
  View,
  Text,
  ColorTokens,
  ThemeValueFallback,
  ViewProps,
} from "tamagui";
import React from "react";
import { brandShadowSm } from "../../../../tamagui.config";

type PillProps = {
  text?: string;
  bg?: ColorTokens | ThemeValueFallback | OpaqueColorValue | null | undefined;
  color?: ColorTokens | ThemeValueFallback | OpaqueColorValue | undefined;
  borderColor?: ColorTokens | ThemeValueFallback | OpaqueColorValue | undefined;
  icon?: React.ReactNode;
  width?: ViewProps["w"];
  height?: ViewProps["h"];
};

export const Pill = ({
  text,
  bg,
  color,
  icon,
  borderColor,
  width = "auto",
  height = 32,
}: PillProps) => {
  return (
    <View
      bg={bg || "$brandYellow"}
      py="$2"
      px="$3"
      h={height}
      w={width}
      borderRadius="$7"
      borderColor={borderColor || bg || "black"}
      borderWidth={1}
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap="$2"
      {...brandShadowSm}
    >
      {icon ? icon : null}
      {text ? (
        <Text
          color={color}
          fontFamily="$heading"
          textTransform="uppercase"
          fontSize={14}
          adjustsFontSizeToFit
          fontWeight={800}
        >
          {text}
        </Text>
      ) : null}
    </View>
  );
};
