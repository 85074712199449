import { Modal } from "react-native";
import { rideAtom } from "../RideForm/atoms";
import { useAtom } from "jotai";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import {
  AnimatePresence,
  Button,
  Label,
  RadioGroup,
  ScrollView,
  Text,
  View,
  XStack,
  YStack,
} from "tamagui";
import { HeaderButton } from "../design-system/Button/HeaderButton";
import { ArrowLeftIcon, CloseIcon } from "../design-system/icons";

import Avatar from "../Avatar";
import { brandShadow } from "../../../tamagui.config";
import { Input } from "../design-system";
import { useState } from "react";
import { debounce } from "lodash";
import { useSearchMembers } from "../../hooks/useSearchMembers";
import { InvitedUser } from "../RideForm/types";

type InvitesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  userId?: string;
  onContinue: (invitedUsers: InvitedUser[]) => void;
  defaultInvitedUsers: InvitedUser[];
  continueText?: string;
};

export const InvitesModal = ({
  isOpen,
  onClose,
  onContinue,
  defaultInvitedUsers,
  continueText = "CONTINUE",
}: InvitesModalProps) => {
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>(
    defaultInvitedUsers ?? []
  );

  const { top, bottom } = useSafeAreaInsets();

  const [searchTerm, setSearchTerm] = useState("");

  const setDebounceSearchTerm = debounce(setSearchTerm, 700);
  const { members } = useSearchMembers(searchTerm);

  const addUserToRide = (user: {
    id: string;
    name: string;
    avatar_url: string | null;
  }) => {
    const isAlreadyInvited = invitedUsers.find((i) => i.id === user.id);

    if (isAlreadyInvited) {
      return;
    }

    setInvitedUsers([...invitedUsers, user]);
  };

  const removeUserFromRide = (user: {
    id: string;
    name: string;
    avatar_url: string | null;
  }) => {
    setInvitedUsers(invitedUsers.filter((i) => i.id !== user.id));
  };

  const onContinuePress = () => {
    onContinue(invitedUsers);
    onClose();
  };

  return (
    <Modal
      visible={isOpen}
      animationType="fade"
      transparent={false}
      onRequestClose={onClose}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{
            paddingTop: top,
            paddingBottom: bottom,
          }}
          bg="$brandParchment"
        >
          <View
            px="$4"
            pb="$4"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <XStack
              justifyContent="space-between"
              w="100%"
              mb="$5"
              alignItems="flex-start"
            >
              <View>
                <HeaderButton onPress={() => onClose()}>
                  <ArrowLeftIcon color="black" width={16} height={16} />
                </HeaderButton>
              </View>
            </XStack>
          </View>
          <View px="$4" flex={1}>
            <Text
              fontSize={16}
              fontFamily="$heading"
              textTransform="uppercase"
              mb="$4"
            >
              Who do you want to invite?
            </Text>

            <Input
              placeholder="Search"
              mb="$4"
              onChangeText={(text) => setDebounceSearchTerm(text)}
            />

            <Text
              fontSize={12}
              fontFamily="$heading"
              textTransform="uppercase"
              mb="$4"
            >
              Invites
            </Text>

            <YStack gap="$4" mb="$5">
              <XStack gap="$2" flexWrap="wrap">
                {invitedUsers.map((r) => (
                  <YStack
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                  >
                    <View
                      position="absolute"
                      top={0}
                      right={0}
                      onPress={() => removeUserFromRide(r)}
                    >
                      <CloseIcon fill="red" width={16} height={16} />
                    </View>
                    <Avatar
                      url={r.avatar_url}
                      size={40}
                      imageStyle={{
                        borderRadius: 20,
                      }}
                    />
                    <Text
                      fontSize={12}
                      w={60}
                      textAlign="center"
                      textOverflow="ellipsis"
                    >
                      {r.name}
                    </Text>
                  </YStack>
                ))}
              </XStack>
            </YStack>
            {members.map((f) => {
              const isInvited = invitedUsers.find((i) => i.id === f.id);

              return (
                <YStack key={f.id}>
                  <XStack
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <YStack flexGrow={1}>
                      <XStack alignItems="center" gap={"$2"}>
                        <Avatar
                          size={32}
                          url={f.avatar_url ?? null}
                          imageStyle={{
                            borderRadius: 20,
                          }}
                        />
                        <Label fontSize={16}>{f.name}</Label>
                      </XStack>
                    </YStack>
                    <View justifyContent="flex-end" alignItems="flex-end">
                      {isInvited ? (
                        <Button
                          size="$3"
                          fontSize={12}
                          bg="red"
                          borderWidth={1}
                          borderColor="black"
                          color="white"
                          onPress={() =>
                            removeUserFromRide({
                              id: f.id ?? "",
                              name: f.name ?? "",
                              avatar_url: f.avatar_url ?? null,
                            })
                          }
                          pressStyle={{
                            bg: "red",
                            shadowColor: "$colorTransparent",
                            shadowOffset: { width: 0, height: 0 },
                            borderWidth: 1,
                            borderColor: "black",
                            scale: 0.98,
                          }}
                          {...brandShadow}
                        >
                          REMOVE
                        </Button>
                      ) : (
                        <Button
                          size="$3"
                          fontSize={12}
                          bg="$brandYellow"
                          borderWidth={1}
                          borderColor="black"
                          onPress={() =>
                            addUserToRide({
                              id: f.id ?? "",
                              name: f.name ?? "",
                              avatar_url: f.avatar_url ?? null,
                            })
                          }
                          pressStyle={{
                            bg: "$white",
                            shadowColor: "$colorTransparent",
                            shadowOffset: { width: 0, height: 0 },
                            borderWidth: 1,
                            borderColor: "black",
                            scale: 0.98,
                          }}
                          {...brandShadow}
                        >
                          ADD
                        </Button>
                      )}
                    </View>
                  </XStack>
                </YStack>
              );
            })}
          </View>
        </ScrollView>
        {invitedUsers.length > 0 ? (
          <View
            flex={1}
            justifyContent="flex-end"
            alignItems="center"
            paddingBottom={bottom}
            px="$4"
            bg="$brandParchment"
            pt={24}
          >
            <AnimatePresence>
              <Button
                onPress={onContinuePress}
                animation="subtle"
                enterStyle={{
                  opacity: 0,
                  scale: 0.95,
                  y: 5,
                }}
                exitStyle={{
                  opacity: 0,
                  scale: 0.95,
                  y: 5,
                }}
                bg="$brandYellow"
                pressStyle={{
                  bg: "$brandYellow",
                  shadowColor: "$colorTransparent",
                  shadowOffset: { width: 0, height: 0 },
                  borderWidth: 1,
                  borderColor: "black",
                  scale: 0.98,
                }}
                borderWidth={1}
                fontSize={16}
                fontFamily="$heading"
                borderColor={"black"}
                w="100%"
                {...brandShadow}
              >
                {continueText}
              </Button>
            </AnimatePresence>
          </View>
        ) : null}
      </SafeAreaView>
    </Modal>
  );
};
