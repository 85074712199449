import { Text, View, XStack } from "tamagui";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { currentUserAtom } from "../../../../atoms/global";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { Loader } from "../../../components/design-system/Loader";
import { HeaderButton } from "../../../components/design-system/Button/HeaderButton";
import { CloseIcon } from "../../../components/design-system/icons";
import { RideForm } from "../../../components/RideForm/RideForm";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { BaseNavigatorParamList } from "../../../navigators/BaseNavigator";
import { useGetEvent } from "../../../hooks/useGetEvent";
import { Ride } from "../../../components/RideForm/types";
import { supabase } from "../../../../lib/supabase";

type EditEventProps = NativeStackScreenProps<
  BaseNavigatorParamList,
  "EditEvent"
>;

export const EditEvent = (props: EditEventProps) => {
  const eventId = props.route.params.eventId;

  const { event } = useGetEvent(eventId);
  const [loading, setLoading] = useState(false);
  const currentUser = useAtomValue(currentUserAtom);

  const navigation = useNavigation();
  const { top, bottom } = useSafeAreaInsets();

  const onSubmit = async (values: Ride) => {
    setLoading(true);
    const { route, invited_users, ...rest } = values;
    const input = {
      ...rest,
      route_id: values.route?.id,
    };
    await supabase.from("events").update(input).eq("id", eventId);
    setLoading(false);
    navigation.goBack();
  };

  const defaultValues: Ride = {
    name: event?.name ?? "",
    description: event?.description ?? "",
    date: event?.date ?? "",
    time: event?.time ?? "",
    groups: event?.groups ?? [],
    terrain: event?.terrain ?? "Road",
    visibility: event?.visibility ?? "Public",
    invited_users: [],
    route: event?.route ?? undefined,
    location: event?.location ?? "",
  };
  console.log("defaultValues", defaultValues);

  return (
    <View flex={1} bg="$brandParchment" paddingTop={top}>
      <View px="$4" pb="$4" justifyContent="flex-start" alignItems="flex-start">
        <XStack justifyContent="space-between" w="100%" alignItems="center">
          <View>
            <HeaderButton onPress={() => navigation.goBack()}>
              <CloseIcon color="black" width={16} height={16} />
            </HeaderButton>
          </View>
          <View justifyContent="flex-end" alignItems="flex-end">
            <Text fontSize={16}>Edit Ride</Text>
          </View>
        </XStack>
      </View>
      {loading ? <Loader /> : null}
      <RideForm
        currentUserId={currentUser?.id ?? ""}
        handleSubmit={onSubmit}
        defaultValues={defaultValues}
        isEditing
      />
    </View>
  );
};
